import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ResourceAuthor = ({ authorName }) => {
  const data = useStaticQuery(graphql`
    query {
      allPagesYaml(filter: { page_key: { eq: "company" } }) {
        edges {
          node {
            id
            team {
              name
              img {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const author = data.allPagesYaml.edges[0].node.team.find(member => {
    return member.name === authorName
  })

  if (!author) return null
  return (
    <>
      <img
        src={author.img.publicURL}
        alt={authorName}
        className="blogpost__author-img"
      />
      <h4 className="blogpost__author">{authorName}</h4>
    </>
  )
}
export default ResourceAuthor
