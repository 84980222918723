import { graphql, Link } from "gatsby"
import React from "react"
import ConsultationCta from "../components/ConsultationCta"
import Layout from "../components/layout"
import ResourceAuthor from "../components/ResourceAuthor"
import SEO from "../components/seo"
import { ShareMenu, ShareMenuItem } from "../components/ShareMenu"
import { buildResourceCategoryPath } from "../../utils"

export const ResourceTemplate = ({
  title,
  author,
  published,
  tags,
  thumbnail,
  html,
  isPreview = false,
  location,
  resourceCategory,
}) => {
  return (
    <article className="container blogpost">
      <header className="blogpost__header">
        {!isPreview && (
          <nav className="resources__breadcrumb">
            <Link to="/resources" className="link--arrow-left">
              Resources
            </Link>

            <Link to={buildResourceCategoryPath(resourceCategory, "").root}>
              {buildResourceCategoryPath(resourceCategory, "").rootLabel}
            </Link>
          </nav>
        )}

        <h1 className="blogpost__title">{title}</h1>

        <div className="blogpost__meta">
          {!isPreview && <ResourceAuthor authorName={author}></ResourceAuthor>}
          <time className="blogpost__date">{published}</time>
          <ul className="blogpost__tags">
            {tags.sort().map(tag => {
              return <li key={tag}>{tag}</li>
            })}
          </ul>
        </div>

        {thumbnail && (
          <img src={thumbnail} alt={title} className="blogpost__hero" />
        )}
      </header>

      <section className="blogpost__body grid">
        <div
          className="blogpost__body-content"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>

        {isPreview === false && (
          <ShareMenu className="blogpost__body-share">
            <ShareMenuItem>
              <a href={`mailto:?body=${location.href}&subject=${title}`}>
                Email
              </a>
            </ShareMenuItem>

            <ShareMenuItem>
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (typeof window !== `undefined`) window.print()
                }}
              >
                Print PDF
              </a>
            </ShareMenuItem>
          </ShareMenu>
        )}
      </section>
    </article>
  )
}

export const pageQuery = graphql`
  query($slug: String!, $type: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    markdownRemark(frontmatter: { type: { eq: $type }, slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        author
        type
        slug
        published(formatString: "MMMM DD, YYYY")
        draft
        tags
        thumbnail {
          childImageSharp {
            fluid(maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ({ data, location }) => {
  const { markdownRemark, site } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <ResourceTemplate
        title={frontmatter.title}
        author={frontmatter.author}
        published={frontmatter.published}
        tags={frontmatter.tags}
        thumbnail={
          frontmatter.thumbnail &&
          frontmatter.thumbnail.childImageSharp.fluid.src
        }
        html={html.replace(/\.\.\/\.\.\/static\/assets/g, "/assets/")}
        isPreview={false}
        location={location}
        resourceCategory={frontmatter.type}
      />
      <ConsultationCta />
    </Layout>
  )
}
